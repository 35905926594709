import styled from "styled-components/macro";

export const fonts = {
  primary: '"Open Sans", sans-serif',
  title: "Ubuntu, sans-serif",
};

export const colors = {
  background: "#f4f4f4",
  border: "#d8d8d8",
  primary: "#00ba00",
  secondary: "#00908f",
  secondary2: "#e2cfc0",
  dark: "#3f3d43",
  black: "#25252b",
  white: "#ffffff",
  green: "#2d908f",
  red: "#ca2c25",
};

export const Title = styled.h1`
  font-family: ${fonts.title};
  font-size: 1.5em;
  color: ${colors.primary};
`;

export const Container = styled.div`
  display: flex;
  background-color: ${colors.background};
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const Card = styled.div`
  flex: 1 1 auto;
  background-color: ${colors.white};
  border: 1px solid ${colors.border};
  box-shadow: 0 0 5px 5px ${colors.border};
  border-radius: 10px;
  padding: 25px;
  margin: 15px;
  width: 80%;
`;

export const Spacer = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

export const Link = styled.a`
  color: ${colors.dark};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Page = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  align-items: center;
`;

export const ModifyButton = styled.button`
  border-radius: 5px;
  background-color: #82c6c5;
  color: ${colors.black};
  padding: 5px 10px;
  border: 1px solid transparent;

  :hover:not(:disabled) {
    background-color: ${colors.background};
    border: 1px solid ${colors.dark};
    cursor: pointer;
  }
  :active:hover:not(:disabled) {
    background-color: ${colors.black};
  }

  :focus {
    outline: none;
  }
`;

export const DeployButton = styled(ModifyButton)`
  background-color: #efafac;
`;
