import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";

import {
  commitCustomerContentsChange,
  deleteCustomerContent,
  fetchCustomerContentSpreadsheets,
  pullCustomerContents,
  pullFeatureFlags,
  putFeatureFlags,
  updateCustomerContent,
} from "../actions/api";
import { DeployButton, ModifyButton } from "../theme";

const CustomerContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > * {
    padding: 10px;
  }
`;

const DiffViewContainer = styled.div`
  width: 100%;
`;

const Divider = styled.div`
  width: 100%;
`;

const DiffContainer = styled.pre`
  flex-grow: 1;
  overflow-x: auto;
  margin: 0;

  li {
    list-style-type: none;
  }
`;

const CreateForm = styled.form`
  display: flex;
  margin-bottom: 20px;
`;

const SpreadsheetIdInput = styled.input`
  width: 500px;
`;

const InputContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: 0 10px;

  span {
    font-size: 10px;
    margin-top: 5px;
    min-height: 14px;
  }
`;

// https://github.com/dtttd/hi-team-dashboard/blob/master/dashboard/src/components/ContentsPull.js#L101:L151
const formatDiff = (strDiff, cid, key, handler) => {
  if (!strDiff) {
    return <li>No changes</li>;
  }

  const lines = strDiff.split("\n");

  const list = lines.map((line, i) => {
    if (line.startsWith("+")) {
      return (
        <li style={{ color: "green", whiteSpace: "pre" }} key={i}>
          {line}
          <br />
        </li>
      );
    }

    if (line.startsWith("-")) {
      return (
        <li style={{ color: "red", whiteSpace: "pre" }} key={i}>
          {line}
          <br />
        </li>
      );
    }

    return (
      <li style={{ color: "gray", whiteSpace: "pre" }} key={i}>
        {line}
        <br />
      </li>
    );
  });

  list.push(
    <li key="button">
      <ModifyButton
        onClick={
          handler ||
          (() =>
            commitCustomerContentsChange({ key, cid })
              .then(() =>
                window.alert("Save completed;\nPull again to verify.")
              )
              .catch((e) =>
                window.alert(`Commit customer content error:\n${e.message}`)
              ))
        }
      >
        Approve and release
      </ModifyButton>
    </li>
  );

  return list;
};

const CustomersContent = () => {
  const [spreadsheets, setSpreadsheets] = useState();
  const [semanticsDiffs, setSemanticsDiffs] = useState({});
  const [semanticsKeys, setSemanticsKeys] = useState({});
  const [journeysDiffs, setJourneysDiffs] = useState({});
  const [journeysKeys, setJourneysKeys] = useState({});
  const [isLoading, setIsLoading] = useState({});

  const [newCid, setNewCid] = useState("");
  const [newSpreadsheetId, setNewSpreadsheetId] = useState("");

  const handleNewCidChange = useCallback((e) => {
    setNewCid(e.target.value);
  }, []);

  const handleNewSpreadsheetIdChange = useCallback((e) => {
    setNewSpreadsheetId(e.target.value);
  }, []);

  const handleAdd = useCallback(
    async (e) => {
      e.preventDefault();

      if (spreadsheets.some(({ cid }) => cid === newCid)) {
        window.alert(`Error. Spreadsheet for customer "${newCid}" exists`);
        return;
      }

      setNewSpreadsheetId("");
      setNewCid("");
      try {
        const item = await updateCustomerContent({
          spreadsheetId: newSpreadsheetId,
          cid: newCid,
        });
        setSpreadsheets([...spreadsheets, item]);
      } catch (e) {
        window.alert(
          `Update customer content spreadsheet error:\n${e.message}`
        );
      }
    },
    [newCid, newSpreadsheetId, spreadsheets]
  );

  const handleDelete = useCallback(
    ({ cid }) => async () => {
      try {
        const isConfirmed = window.confirm(
          `Are you sure you want to remove spreadsheet for customer "${cid}"?`
        );

        if (!isConfirmed) {
          return;
        }

        await deleteCustomerContent({
          cid,
        });
        setSpreadsheets(
          spreadsheets.filter((spreadsheet) => spreadsheet.cid !== cid)
        );
      } catch (e) {
        window.alert(
          `Delete customer content spreadsheet error:\n${e.message}`
        );
      }
    },
    [spreadsheets]
  );

  useEffect(() => {
    fetchCustomerContentSpreadsheets()
      .then((data) => setSpreadsheets(data))
      .catch((e) => {
        setSpreadsheets([]);
        window.alert(
          `Fetch customer content spreadsheets map error:\n${e.message}`
        );
      });
  }, []);

  const pullCustomerContentsCallback = useCallback(
    ({ cid }) => ([
      { diff: semanticsDiff, key: semanticsKey },
      { diff: journeysDiff, key: journeysKey },
    ]) => {
      setSemanticsKeys({
        ...semanticsKeys,
        [cid]: semanticsKey,
      });
      setSemanticsDiffs({
        ...semanticsDiffs,
        [cid]: semanticsDiff,
      });
      setJourneysKeys({
        ...journeysKeys,
        [cid]: journeysKey,
      });
      setJourneysDiffs({
        ...journeysDiffs,
        [cid]: journeysDiff,
      });
      setIsLoading({ [cid]: false });
    },
    [semanticsDiffs, semanticsKeys, journeysDiffs, journeysKeys]
  );

  const handleReset = useCallback(
    ({ cid }) => () => {
      setSemanticsKeys({
        ...semanticsKeys,
        [cid]: null,
      });
      setSemanticsDiffs({
        ...semanticsDiffs,
        [cid]: null,
      });
      setJourneysKeys({
        ...journeysKeys,
        [cid]: null,
      });
      setJourneysDiffs({
        ...journeysDiffs,
        [cid]: null,
      });
    },
    [semanticsDiffs, semanticsKeys, journeysDiffs, journeysKeys]
  );

  const [edits, setEdits] = useState({});

  const handleEdit = useCallback(
    ({ cid, spreadsheetId }) => async () => {
      handleReset({ cid })();
      setEdits({
        [cid]: spreadsheetId,
      });
    },
    [handleReset]
  );

  const handleEditChange = useCallback(
    ({ cid }) => async (e) => {
      setEdits({
        [cid]: e.target.value,
      });
    },
    []
  );

  const handleEditCancel = useCallback(
    ({ cid }) => async () => {
      const newEdits = { ...edits };
      delete newEdits[cid];
      setEdits(newEdits);
    },
    [edits]
  );

  const handleEditSave = useCallback(
    ({ cid }) => async () => {
      try {
        await updateCustomerContent({ cid, spreadsheetId: edits[cid] });
        await handleEditCancel({ cid })();
        window.alert("Successfully saved!");
      } catch (e) {
        window.alert(`Update customer content error:\n${e.message}`);
      }
    },
    [edits, handleEditCancel]
  );

  return (
    <>
      <h3>Custom content spreadsheets</h3>
      <CreateForm onSubmit={handleAdd}>
        <label>
          Company ID* (cid):
          <InputContainer>
            <input value={newCid} onChange={handleNewCidChange} required />
            <span />
          </InputContainer>
        </label>
        <label>
          Spreadsheet ID*:
          <InputContainer>
            <SpreadsheetIdInput
              value={newSpreadsheetId}
              onChange={handleNewSpreadsheetIdChange}
              required
            />
            <span>
              https://docs.google.com/spreadsheets/d/
              <b>17DmC9ocMNk_G3J4cUfJD20I7adffI1X326_y7iQLmXE</b>/edit#gid=0
            </span>
          </InputContainer>
        </label>
        <div>
          <DeployButton type="submit">Add</DeployButton>
        </div>
      </CreateForm>
      {spreadsheets
        ? spreadsheets.map(({ cid, spreadsheetId }, i, array) => (
            <CustomerContentContainer key={cid}>
              <span>{cid}</span>
              {edits[cid] ? (
                <div>
                  <SpreadsheetIdInput
                    value={edits[cid]}
                    onChange={handleEditChange({ cid })}
                    required
                  />
                </div>
              ) : (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://docs.google.com/spreadsheets/d/${spreadsheetId}`}
                >
                  Open custom content spreadsheet
                </a>
              )}

              {!edits[cid] && (
                <div>
                  <ModifyButton onClick={handleEdit({ cid, spreadsheetId })}>
                    Edit
                  </ModifyButton>
                </div>
              )}
              {edits[cid] && (
                <>
                  <div>
                    <ModifyButton
                      onClick={handleEditSave({ cid })}
                      disabled={edits[cid] === spreadsheetId}
                    >
                      Save
                    </ModifyButton>
                  </div>
                  <div>
                    <DeployButton onClick={handleEditCancel({ cid })}>
                      Cancel edit
                    </DeployButton>
                  </div>
                </>
              )}
              <div>
                <DeployButton onClick={handleDelete({ cid })}>
                  Delete
                </DeployButton>
              </div>

              {!edits[cid] && (
                <div>
                  <DeployButton
                    onClick={() => {
                      setIsLoading({ ...isLoading, [cid]: true });
                      pullCustomerContents({ cid, spreadsheetId })
                        .then(pullCustomerContentsCallback({ cid }))
                        .catch((e) => {
                          setIsLoading({ ...isLoading, [cid]: false });
                          window.alert(
                            `Fetch customer content error:\n${e.message}`
                          );
                        });
                    }}
                  >
                    Pull custom content spreadsheet
                  </DeployButton>
                </div>
              )}
              {typeof semanticsDiffs[cid] === "string" && (
                <div>
                  <ModifyButton onClick={handleReset({ cid })}>
                    Cancel
                  </ModifyButton>
                </div>
              )}

              {isLoading[cid] && <div>Loading...</div>}

              {typeof semanticsDiffs[cid] === "string" && (
                <DiffViewContainer>
                  <h4>{semanticsKeys[cid]}</h4>

                  <DiffContainer>
                    {formatDiff(semanticsDiffs[cid], cid, semanticsKeys[cid])}
                  </DiffContainer>
                </DiffViewContainer>
              )}

              {typeof journeysDiffs[cid] === "string" && (
                <DiffViewContainer>
                  <h4>{journeysKeys[cid]}</h4>
                  <DiffContainer>
                    {formatDiff(journeysDiffs[cid], cid, journeysKeys[cid])}
                  </DiffContainer>
                </DiffViewContainer>
              )}

              {i !== array.length - 1 && (
                <Divider>
                  <hr />
                </Divider>
              )}
            </CustomerContentContainer>
          ))
        : "Loading"}
    </>
  );
};

const HIContent = () => {
  const [semanticsDiff, setSemanticsDiff] = useState(null);
  const [semanticsKey, setSemanticsKey] = useState(null);
  const [journeysDiff, setJourneysDiff] = useState(null);
  const [journeysKey, setJourneysKey] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const pullCustomerContentsCallback = useCallback(
    () => ([
      { diff: _semanticsDiff, key: _semanticsKey },
      { diff: _journeysDiff, key: _journeysKey },
    ]) => {
      setSemanticsKey(_semanticsKey);
      setSemanticsDiff(_semanticsDiff);
      setJourneysKey(_journeysKey);
      setJourneysDiff(_journeysDiff);
      setIsLoading(false);
    },
    []
  );

  const handleReset = useCallback(() => {
    setSemanticsKey(null);
    setSemanticsDiff(null);
    setJourneysKey(null);
    setJourneysDiff(null);
  }, []);

  return (
    <>
      <h3>HI generic contents spreadsheet</h3>
      <CustomerContentContainer>
        <div>
          <DeployButton
            onClick={() => {
              setIsLoading(true);
              pullCustomerContents({})
                .then(pullCustomerContentsCallback())
                .catch((e) => {
                  setIsLoading(false);
                  window.alert(`Fetch HI content error:\n${e.message}`);
                });
            }}
          >
            Pull HI generic content spreadsheet
          </DeployButton>
        </div>

        {isLoading && <div>Loading...</div>}

        {typeof semanticsDiff === "string" && (
          <div>
            <ModifyButton onClick={handleReset}>Cancel</ModifyButton>
          </div>
        )}

        {typeof semanticsDiff === "string" && (
          <DiffViewContainer>
            <h4>{semanticsKey}</h4>

            <DiffContainer>
              {formatDiff(semanticsDiff, null, semanticsKey)}
            </DiffContainer>
          </DiffViewContainer>
        )}

        {typeof journeysDiff === "string" && (
          <DiffViewContainer>
            <h4>{journeysKey}</h4>
            <DiffContainer>
              {formatDiff(journeysDiff, null, journeysKey)}
            </DiffContainer>
          </DiffViewContainer>
        )}
      </CustomerContentContainer>
    </>
  );
};

const Features = () => {
  const [featuresDiff, setFeaturesDiff] = useState(null);
  const [features, setFeatures] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = useCallback(() => {
    setFeaturesDiff(null);
  }, []);

  const handlePull = useCallback(() => {
    setIsLoading(true);
    pullFeatureFlags()
      .then(({ diff, value }) => {
        setFeaturesDiff(diff);
        setFeatures(value);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        window.alert(`Fetch HI features error:\n${e.message}`);
      });
  }, []);

  const handlePut = useCallback(() => {
    putFeatureFlags({ value: features })
      .then(() => window.alert("Save completed;\nPull again to verify."))
      .catch((e) => window.alert(`Commit feature flags error:\n${e.message}`));
  }, [features]);

  return (
    <>
      <h3>HI feature flags</h3>
      <CustomerContentContainer>
        <div>
          <DeployButton onClick={handlePull}>
            Pull HI feature flags
          </DeployButton>
        </div>

        {isLoading && <div>Loading...</div>}

        {typeof featuresDiff === "string" && (
          <div>
            <ModifyButton onClick={handleReset}>Cancel</ModifyButton>
          </div>
        )}

        {typeof featuresDiff === "string" && (
          <DiffViewContainer>
            <DiffContainer>
              {formatDiff(featuresDiff, null, null, handlePut)}
            </DiffContainer>
          </DiffViewContainer>
        )}
      </CustomerContentContainer>
    </>
  );
};

export { CustomersContent, HIContent, Features };
