import { Auth } from "aws-amplify";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import Jimp from "jimp";
import JSZip from "jszip";
import mime from "mime-types";

AWS.config.region = "eu-west-1";

const getS3 = async () => {
  const credentials = await Auth.currentCredentials();
  return new AWS.S3({
    credentials: Auth.essentialCredentials(credentials),
    retry: false,
    region: "eu-west-1",
  });
};

export const uploadFile = async ({
  body,
  name,
  type,
  prefix,
  isResizeImage,
}) => {
  const ext = name.split(".").pop();
  const key = `${prefix ? `${prefix}/` : ""}${uuidv4()}.${ext}`;

  let preparedBody = body;
  if (isResizeImage && [Jimp.MIME_JPEG, Jimp.MIME_PNG].includes(type)) {
    const image = await Jimp.read(preparedBody);
    preparedBody = await image
      .resize(600, Jimp.AUTO)
      .quality(75)
      .getBufferAsync(type);
  }

  const s3 = await getS3();

  const params = {
    Bucket: process.env.REACT_APP_media_bucket_name,
    Key: key,
    Body: preparedBody,
    ContentType: type,
  };

  await s3.putObject(params).promise();

  return {
    url: `https://${process.env.REACT_APP_account_media_domain_name}/${key}`,
  };
};

export const deploySpreadsheet = async () => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const lambda = new AWS.Lambda({
    ...creds,
    retry: false,
  });

  return lambda
    .invoke({
      FunctionName: process.env.REACT_APP_lambda_deploy_spreadsheet_lambda_name,
      Payload: JSON.stringify({ env: "prod" }),
    })
    .promise();
};

export const pullSpreadsheet = async () => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const lambda = new AWS.Lambda({
    ...creds,
    retry: false,
  });

  return lambda
    .invoke({
      FunctionName: process.env.REACT_APP_lambda_pull_spreadsheet_lambda_name,
      Payload: JSON.stringify({ env: "prod" }),
    })
    .promise();
};

export const fetchCustomerContentSpreadsheets = async () => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const documentClient = new AWS.DynamoDB.DocumentClient(creds);

  const { Items } = await documentClient
    .scan({
      TableName: process.env.REACT_APP_customers_content_table_name,
    })
    .promise();
  return Items;
};

export const pullCustomerContents = async ({ cid, spreadsheetId }) => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const lambda = new AWS.Lambda({
    ...creds,
    retry: false,
    httpOptions: { timeout: 600000 },
  });

  const invokeOpts = {
    Payload: JSON.stringify({ cid, spreadsheetId, env: "prod" }),
  };

  const functionsInvocations = [
    "hi-pull-semantics-spreadsheet",
    "hi-pull-journeys-spreadsheet",
  ].map((functionName) =>
    lambda
      .invoke({
        ...invokeOpts,
        FunctionName: `arn:aws:lambda:eu-west-1:${process.env.REACT_APP_account_content_account_id}:function:${functionName}`,
      })
      .promise()
      .then((r) => {
        const payload = JSON.parse(r.Payload.toString("utf-8"));

        if (payload.errorMessage) {
          throw new Error(payload.errorMessage);
        }

        return payload;
      })
  );

  return Promise.all(functionsInvocations);
};

export const commitCustomerContentsChange = async ({ key, cid }) => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const lambda = new AWS.Lambda({ ...creds });

  const invokeOpts = {
    Payload: JSON.stringify({ key, cid }),
  };

  await lambda
    .invoke({
      ...invokeOpts,
      FunctionName: `arn:aws:lambda:eu-west-1:${process.env.REACT_APP_account_content_account_id}:function:hi-pull-commit-changes`,
    })
    .promise()
    .then((r) => {
      const payload = JSON.parse(r.Payload.toString("utf-8"));

      if (payload.errorMessage) {
        throw new Error(payload.errorMessage);
      }

      return JSON.parse(payload);
    });
};

export const updateCustomerContent = async ({ cid, spreadsheetId }) => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const documentClient = new AWS.DynamoDB.DocumentClient(creds);

  const item = {
    cid,
    spreadsheetId,
    timestamp: new Date().getTime(),
  };

  await documentClient
    .put({
      TableName: process.env.REACT_APP_customers_content_table_name,
      Item: item,
    })
    .promise();

  return item;
};

export const deleteCustomerContent = async ({ cid }) => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const documentClient = new AWS.DynamoDB.DocumentClient(creds);
  const lambda = new AWS.Lambda(creds);

  const invokeOpts = {
    Payload: JSON.stringify({ accountName: "prod", cid }),
  };

  const response = await lambda
    .invoke({
      ...invokeOpts,
      FunctionName: `arn:aws:lambda:eu-west-1:${process.env.REACT_APP_account_content_account_id}:function:delete-customer-content:current`,
    })
    .promise();
  const payload = JSON.parse(response.Payload.toString("utf-8"));

  if (payload?.errorMessage) {
    throw new Error(payload.errorMessage);
  }

  await documentClient
    .delete({
      TableName: process.env.REACT_APP_customers_content_table_name,
      Key: { cid },
    })
    .promise();
};

let request = null;

export const pullFeatureFlags = async () => {
  if (request) {
    request.abort();
  }

  let creds = Auth.essentialCredentials(await Auth.currentCredentials());

  const sts = new AWS.STS(creds);

  request = sts.assumeRole({
    RoleArn: `arn:aws:iam::${
      process.env.REACT_APP_account_hi_account_ids.split(",")[0]
    }:role/FeatureFlagsParametersRole`,
    RoleSessionName: "FeatureFlagsParametersRole",
  });

  const assumeRoleData = await request.promise();

  const featureFlagsParametersRoleCreds = sts.credentialsFrom(
    assumeRoleData,
    creds
  );

  const ssm = new AWS.SSM(featureFlagsParametersRoleCreds);

  request = ssm.getParameter({
    Name: "/account/features_list",
  });

  let response = await request.promise();

  creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const lambda = new AWS.Lambda({
    ...creds,
    httpOptions: { timeout: 300000 },
  });

  let currentValue = response.Parameter.Value;

  try {
    currentValue = JSON.parse(response.Parameter.Value);
  } catch (_) {}

  request = lambda.invoke({
    FunctionName: `arn:aws:lambda:${AWS.config.region}:${process.env.REACT_APP_account_content_account_id}:function:hi-pull-feature-flags-spreadsheet`,
    Payload: JSON.stringify({
      currentValue,
    }),
  });

  response = await request.promise();
  const payload = JSON.parse(response.Payload);

  if (payload.errorMessage) {
    throw new Error(payload.errorMessage);
  }

  return payload;
};

export const putFeatureFlags = async ({ value }) => {
  if (request) {
    request.abort();
  }

  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const sts = new AWS.STS(creds);

  const requests = process.env.REACT_APP_account_hi_account_ids.split(",").map(
    async (accountId) => {
      request = sts.assumeRole({
        RoleArn: `arn:aws:iam::${accountId}:role/FeatureFlagsParametersRole`,
        RoleSessionName: "FeatureFlagsParametersRole",
      });

      const assumeRoleData = await request.promise();

      const featureFlagsParametersRoleCreds = sts.credentialsFrom(
        assumeRoleData,
        creds
      );

      const ssm = new AWS.SSM(featureFlagsParametersRoleCreds);

      request = ssm.putParameter({
        Name: "/account/features_list",
        Value: JSON.stringify(value),
        Overwrite: true,
      });

      return request.promise();
    }
  );

  await Promise.all(requests);
};

export const listTemplates = async () => {
  const creds = Auth.essentialCredentials(await Auth.currentCredentials());
  const lambda = new AWS.Lambda({
    ...creds,
    retry: false,
  });

  const invokeOpts = {
    Payload: JSON.stringify({ accountName: "prod" }),
  };

  const response = await lambda
    .invoke({
      ...invokeOpts,
      FunctionName: `arn:aws:lambda:eu-west-1:${process.env.REACT_APP_account_content_account_id}:function:list-templates`,
    })
    .promise();
  const payload = JSON.parse(response.Payload.toString("utf-8"));

  if (payload.errorMessage) {
    throw new Error(payload.errorMessage);
  }

  return payload;
};

export const uploadHTMLZip = async ({ body, name }) => {
  const zip = new JSZip();

  const filename = name.split(".").slice(0, -1).join(".");
  const key = `html/${filename}`;
  const data = await zip.loadAsync(body);

  const htmlFile = Object.values(data.files).find(
    ({ name, dir }) =>
      !dir && name.toLowerCase().endsWith(".html") && !name.includes("/")
  );

  if (!htmlFile) {
    throw new Error("zip hasn't html file inside in the root!");
  }

  await Promise.all(
    Object.values(data.files)
      .filter(({ dir }) => !dir)
      .map(async ({ name }) => {
        const body = await zip.file(name).async("nodebuffer");
        const s3 = await getS3();
        const contentType = mime.lookup(name);

        const params = {
          Bucket: process.env.REACT_APP_media_bucket_name,
          Key: `${key}/${name}`,
          Body: body,
          ContentType: contentType,
        };

        await s3.putObject(params).promise();
      })
  );

  return {
    url: `https://${process.env.REACT_APP_account_media_domain_name}/${key}/${htmlFile.name}`,
  };
};
