import React, { useCallback, useEffect, useState } from "react";
import JsonView from "react-json-view";
import DebounceInput from "react-debounce-input";
import Highlighter from "react-highlight-words";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

import { listTemplates } from "../actions/api";

const inputProps = {
  inputComponent: DebounceInput,
  inputProps: {
    debounceTimeout: 500,
  },
};

// eslint-disable-next-line react/prop-types
const Template = ({ src }) => {
  const [template, setTemplate] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    fetch(src)
      .then((response) => response.json())
      .then((template) => setTemplate(template))
      .finally(() => setIsLoading(false));
  }, [src]);

  return isLoading ? (
    <Box p={2} display="flex" justifyContent="center" width="100%">
      <CircularProgress />
    </Box>
  ) : (
    <JsonView displayDataTypes={false} name={false} src={template} />
  );
};

const Templates = () => {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleClear = useCallback(() => {
    setSearchValue("");
  }, []);

  const handleSearch = useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    listTemplates()
      .then((templates) => setTemplates(templates))
      .catch((e) => alert(`Templates fetch error:\n${e.message}`))
      .finally(() => setIsLoading(false));
  }, []);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <h3>Advanced - License keys details (templates)</h3>
        </Grid>
        <Grid item>
          <TextField
            label="Search"
            InputLabelProps={{ shrink: true }}
            margin="none"
            onChange={handleSearch}
            value={searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              ...(searchValue && {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="medium" onClick={handleClear}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }),
              ...inputProps,
            }}
          />
        </Grid>
      </Grid>

      {isLoading && <div>Loading...</div>}
      {!isLoading &&
        templates
          .filter(({ Name }) => !searchValue || Name.includes(searchValue))
          .map(({ Name, Url }) => (
            <Accordion key={Name} TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography variant="subtitle1">
                      <Highlighter
                        searchWords={[searchValue]}
                        autoEscape={true}
                        textToHighlight={Name}
                      />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <a href={Url} target="_blank" rel="noreferrer noopener">
                      Open
                    </a>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Template src={Url} />
              </AccordionDetails>
            </Accordion>
          ))}
    </>
  );
};

export default Templates;
