import { useCallback, useState } from "react";

const useIsLoading = (action) => {
  const [isLoading, setIsLoading] = useState(false);
  const handler = useCallback(
    async (...params) => {
      try {
        setIsLoading(true);
        await action(...params);
      } finally {
        setIsLoading(false);
      }
    },
    [action]
  );

  return { isLoading, handler };
};

export default useIsLoading;
