import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Amplify, { Auth } from "aws-amplify";

// @TODO get data from SSM
Amplify.configure({
  Auth: {
    identityPoolId: "eu-west-1:3e3cfd57-b3c5-4f17-9cd6-db2859d21bea",
    region: "eu-west-1",
    userPoolId: "eu-west-1_YyT1hlX7d",
    userPoolWebClientId: "34ihosh525ki2k9lrkob9c95mi",

    oauth: {
      domain: `sso.${process.env.REACT_APP_account_domainName}`,
      options: {},
      redirectSignIn: `${window.location.protocol}//${window.location.host}/`,
      redirectSignOut: `${window.location.protocol}//${window.location.host}/`,
      responseType: "code",
      scope: ["openid", "profile", "email", "aws.cognito.signin.user.admin"],
    },
  },
});

window.Auth = Auth;

ReactDOM.render(<App />, document.getElementById("root"));
