import React, { useCallback } from "react";
import styled from "styled-components/macro";
import { Auth } from "aws-amplify";

import { colors, Container, Spacer } from "../theme";
import PropTypes from "prop-types";

const LoginForm = styled(Container)``;
const LoginButton = styled.a`
  cursor: pointer;
  margin-top: 50px;
`;

const AppLogo = styled.img`
  width: 150px;
`;

const Footer = styled.p`
  font-size: smaller;
  color: ${colors.dark};
`;

const SignIn = ({ authState }) => {
  const signIn = useCallback(
    () => Auth.federatedSignIn({ customProvider: "OKTA" }),
    []
  );

  if (authState !== "signIn") {
    return false;
  }

  return (
    <LoginForm>
      <Spacer />
      <AppLogo src="/hi_logo.svg" />

      <LoginButton onClick={signIn}>
        <span>Sign In with OKTA</span>
      </LoginButton>

      <Spacer />
      <Footer>
        &copy; {new Date().getFullYear()} Habit Inspiring Technology GmbH
      </Footer>
    </LoginForm>
  );
};

SignIn.propTypes = {
  authState: PropTypes.string,
};

SignIn.defaultProps = {
  authState: null,
};

export default SignIn;
