import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";

const FileUpload = ({ children, accept, onChange }) => {
  const inputRef = useRef(null);
  const upload = useCallback(() => {
    if (inputRef) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const handleChange = useCallback(
    (e) => {
      const input = e.target;
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (onChange) {
          input.value = "";
          onChange({
            name: file.name,
            type: file.type,
            body: reader.result,
          });
        }
      };

      reader.readAsArrayBuffer(file);
    },
    [onChange]
  );

  return (
    <>
      <input
        type="file"
        accept={accept}
        hidden
        ref={inputRef}
        onChange={handleChange}
      />
      {children({ upload })}
    </>
  );
};

FileUpload.propTypes = {
  children: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  accept: PropTypes.string,
};

export default FileUpload;
