import React, { useState, useCallback } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import styled from "styled-components/macro";

import { Card, Page, ModifyButton, DeployButton } from "../theme";
import FileUpload from "../components/FileUpload";
import ReactDiffViewer from "react-diff-viewer";

import {
  deploySpreadsheet,
  pullSpreadsheet,
  uploadFile,
  uploadHTMLZip,
} from "../actions/api";
import { CustomersContent, Features, HIContent } from "../components/Content";
import Templates from "../components/Templates";
import useIsLoading from "../utils/useIsLoading";

const ProfileContainer = styled(Page)``;

const UploadButton = styled(ModifyButton)`
  margin: 0 15px 0 0;
  display: inline-flex;
  align-items: center;

  > :last-child {
    margin-left: 10px;
  }
`;

const DeployChangesButton = styled(DeployButton)`
  margin: 15px 0 0;
`;

const DiffContainer = styled.div`
  margin-top: 10px;
  width: 100%;
`;
const ResizeCheckbox = styled.label`
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
`;

const Profile = () => {
  const [currentTemplates, setCurrentTemplates] = useState();
  const [templates, setTemplates] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [isResizeImage, setIsResizeImage] = useState(true);

  const handleIsResizeImageChanged = useCallback((e) => {
    setIsResizeImage(e.target.checked);
  }, []);

  const handleUploadFile = useCallback(
    async (payload) => {
      const { url } = await uploadFile({
        ...payload,
        isResizeImage,
        prefix: "general",
      });

      prompt("File url: ", url);
    },
    [isResizeImage]
  );

  const handleUploadHTMLZip = useCallback(async (payload) => {
    try {
      const { url } = await uploadHTMLZip(payload);

      prompt("Url: ", url);
    } catch (e) {
      alert(`HTML zip upload error: ${e.message}`);
    }
  }, []);

  const {
    handler: handleUploadHTMLZipWithLoading,
    isLoading: isHTMLZipUploading,
  } = useIsLoading(handleUploadHTMLZip);

  const handlePullCustomersSpreadsheet = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await pullSpreadsheet();
      const payload = JSON.parse(response.Payload);

      if (payload.errorMessage) {
        window.alert(payload.errorMessage);
        return;
      }

      setTemplates(JSON.stringify(payload.templates, null, "\t"));
      setCurrentTemplates(JSON.stringify(payload.currentTemplates, null, "\t"));
    } catch (e) {
      window.alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleDeployCustomersSpreadsheet = useCallback(async () => {
    const isConfirmed = window.confirm(
      "Are you sure that you want start changes deploy?"
    );

    if (!isConfirmed) {
      return;
    }

    try {
      const response = await deploySpreadsheet();
      const payload = JSON.parse(response.Payload);

      if (payload.errorMessage) {
        window.alert(payload.errorMessage);
        return;
      }

      window.alert(
        `Successfully deployed!\n\n${payload.templates
          .map(
            ({ templateName }, i) =>
              `Template: ${templateName}, ETag: ${payload.s3responses[i]?.ETag}`
          )
          .join("\n")}`
      );
    } catch (e) {
      window.alert(e.message);
    }
  }, []);

  return (
    <ProfileContainer>
      <Card>
        <h3>HI content</h3>
        <FileUpload onChange={handleUploadFile}>
          {({ upload }) => (
            <UploadButton onClick={upload}>Upload file</UploadButton>
          )}
        </FileUpload>
        <ResizeCheckbox>
          Resize image{" "}
          <input
            type="checkbox"
            checked={isResizeImage}
            onChange={handleIsResizeImageChanged}
          />
        </ResizeCheckbox>
        <Box mt={1}>
          <FileUpload accept=".zip" onChange={handleUploadHTMLZipWithLoading}>
            {({ upload }) => (
              <UploadButton onClick={upload}>
                Upload HTML zip{" "}
                {isHTMLZipUploading && <CircularProgress size={15} />}
              </UploadButton>
            )}
          </FileUpload>
        </Box>

        <hr />

        <h3>HI licenses</h3>
        <DeployButton onClick={handlePullCustomersSpreadsheet}>
          Pull licenses
        </DeployButton>

        {isLoading && <DiffContainer>Loading...</DiffContainer>}

        {templates && currentTemplates && (
          <DiffContainer>
            {templates === currentTemplates ? (
              "No changes"
            ) : (
              <>
                <ReactDiffViewer
                  oldValue={currentTemplates}
                  newValue={templates}
                  splitView={false}
                  showDiffOnly
                />
                <DeployChangesButton onClick={handleDeployCustomersSpreadsheet}>
                  Deploy changes
                </DeployChangesButton>
              </>
            )}
          </DiffContainer>
        )}
        <HIContent />
        <Features />
        <CustomersContent />
        <Templates />
      </Card>
    </ProfileContainer>
  );
};

export default Profile;
