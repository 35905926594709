import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { Authenticator } from "aws-amplify-react";
import { Auth } from "aws-amplify";
import Profile from "./screens/Profile";
import SignIn from "./screens/SignIn";
import { colors, Container, Spacer, Link, Title } from "./theme";

const AppContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: pink;
  box-sizing: border-box;
`;

const Header = styled.header`
  box-shadow: 0 0 1px 3px ${colors.secondary};
  z-index: 1000;
  background-color: ${colors.secondary};
  color: ${colors.white};
  padding: 3px 20px 0 20px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const AppLogo = styled.img`
  display: flex;
  height: 35px;
  margin-right: 20px;
`;

const AppTitle = styled(Title)`
  color: ${colors.white};
`;

const Logout = styled(Link)`
  display: flex;
  flex: 0 1 content;
  justify-self: flex-end;
  padding: 10px;
  color: ${colors.white};
  text-decoration: none;
  font-weight: bold;
`;

const App = ({ authState }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  const logout = useCallback(() => Auth.signOut(), []);

  useEffect(() => {
    Auth.currentUserInfo().then((user) => {
      setIsLoading(false);
      setUserInfo(user);
    });
  }, []);

  if (authState !== "signedIn" && authState !== "verifyContact") {
    return null;
  }

  return (
    <AppContainer>
      <Header>
        <AppLogo src="/hi_logo.svg" />
        <AppTitle>Customers Management Portal (Customers Admin)</AppTitle>
        <Spacer />
        <Logout onClick={logout}>Logout</Logout>
      </Header>
      <Container>
        <marquee
          style={{
            width: "100%",
            backgroundColor: "red",
            fontWeight: "bold",
            textAlign: "center",
            padding: 10,
            color: "white",
          }}
        >
          !!! THIS DASHBOARD DEPLOYS CONTENT TO PRODUCTION !!!
        </marquee>
      </Container>
      <Container>
        {userInfo && !isLoading ? <Profile /> : "Loading..."}
      </Container>
    </AppContainer>
  );
};

App.propTypes = {
  authState: PropTypes.string,
};

App.defaultProps = {
  authState: null,
};

// eslint-disable-next-line react/display-name
export default () => (
  <Authenticator hideDefault>
    <SignIn />
    <App />
  </Authenticator>
);
